import type { Space } from '@cocoonspace/sdk-js/types/space'
import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Price } from '~/components/price'

export const TimeBasedPrice = ({
	type,
	space,
}: { type: 'hour' | 'day'; space: Pick<Space, 'price' | 'prices'> }) => {
	const router = useRouter()
	const { t } = useTranslation()

	const computeDuration = () => {
		if (type === 'day') {
			// * a workaround to handle both ways to manage multiple days query params
			// * the in-house one: "?day[]=1&day[]=2" (for instance from the search page)
			// * and the one from nuqs: "?day=1,2" (from venue landing page)
			let count = 1

			if (Array.isArray(router.query.day)) {
				count = router.query.day.length
			} else if (typeof router.query.day === 'string') {
				count = router.query.day.split(',').length
			}

			return `${count} ${t('common:day', { count })}`
		}

		const duration = dateTimeUtils(router.query.end as string, 'hh:mm').diff(
			dateTimeUtils(router.query.start as string, 'hh:mm'),
			'hour',
			true,
		)

		if (duration === 0.5) return t('common:halfHour')

		return dateTimeUtils
			.duration(duration, 'hour')
			.format('H[h]mm')
			.replace('h00', 'h')
	}

	const duration = computeDuration()

	return (
		<div className='flex flex-col items-end gap-y-1'>
			<span className='text-xs'>
				{t('search:results.priceForX', { duration })}
			</span>

			<Price price={space.price} />
		</div>
	)
}
