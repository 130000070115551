import { authEmitter } from '@cocoonspace/shared/domains/auth/emitters/auth.emitter'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { useValidateEmail } from '@cocoonspace/shared/hooks/use-emailable'
import { Button, buttonVariants } from '@cocoonspace/ui/components/button'
import { Form } from '@cocoonspace/ui/components/form'
import { FormControlledInput } from '@cocoonspace/ui/components/form-controlled-input'
import { PasswordCreationInput } from '@cocoonspace/ui/components/password-creation-input'
import { Separator } from '@cocoonspace/ui/components/separator'
import { zodResolver } from '@hookform/resolvers/zod'
import { i18n, Trans, useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { ValidatedEmailInput } from '~/components/ui/validated-email-input'
import { AppleConnectButton, GoogleConnectButton } from './social-auth-list'

type FormData = z.infer<typeof registerSchema>

const registerSchema = z.object({
	email: z.string().email({ message: i18n?.t('errors:forms.invalidEmail') }),
	password: z
		.string()
		.min(8, { message: i18n?.t('errors:forms.password.minLength') })
		.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, {
			message: i18n?.t('errors:forms.password.specialCharacters'),
		}),
})

export const RegisterForm = () => {
	const { t } = useTranslation()
	const { createUserWithEmailAndPassword, authError } = useAuth()
	const closeDialog = useAuthDialogStore((state) => state.closeDialog)
	const setActiveTab = useAuthDialogStore((state) => state.setActiveTab)
	const initialData = useAuthDialogStore((state) => state.initialData)

	const form = useForm<FormData>({
		resolver: zodResolver(registerSchema),
		values: {
			email: '',
			password: '',
			...initialData,
		},
	})

	const { validate } = useValidateEmail(form)

	const onSubmitForm = async (data: FormData) => {
		const isValid = await validate(data.email!)

		if (isValid) {
			return createUserWithEmailAndPassword(data.email!, data.password!).then(
				(res) => {
					if (res?.user) {
						authEmitter.emit('register.success')
						closeDialog()
					}
				},
			)
		}
	}

	return (
		<div className='flex flex-col gap-3'>
			{!!authError && (
				<p className='mb-4 px-6 text-primary text-sm'>{t(authError)}</p>
			)}

			<form
				className='flex flex-col gap-y-6 px-6'
				noValidate
				onSubmit={form.handleSubmit(onSubmitForm)}
			>
				<Form {...form}>
					<div className='flex flex-col'>
						<ValidatedEmailInput
							name='email'
							label={t('auth:register.email')}
							required
						/>

						<FormControlledInput
							name='password'
							label={t('auth:register.password')}
							control={form.control}
							render={({ field, fieldState }) => (
								<PasswordCreationInput
									{...field}
									required
								/>
							)}
							required
						/>

						<Trans
							parent='p'
							className='text-center text-sm'
							i18nKey='auth:register.legal'
							components={{
								cguLink: (
									<Link
										className={buttonVariants({ variant: 'link', size: 'sm' })}
										href='/mentions-legales'
										target='_blank'
									/>
								),
							}}
						/>
					</div>

					<div className='flex flex-col gap-y-4'>
						<Button
							data-testid='register-btn'
							className='w-full'
							type='submit'
							disabled={form.formState.isSubmitting}
						>
							{t('auth:register.createMyAccount')}
						</Button>

						<div className='flex flex-row items-center gap-x-4 leading-none'>
							<Separator className='flex-1' />

							<div className='text-center'>{t('common:or')}</div>

							<Separator className='flex-1' />
						</div>

						<GoogleConnectButton action='register' />

						<AppleConnectButton action='register' />
					</div>
				</Form>
			</form>

			<Separator />

			<div className='flex flex-row justify-center gap-x-2 px-6'>
				{t('auth:register.account')}

				<button
					type='button'
					className='text-primary'
					onClick={() => setActiveTab('login')}
				>
					{t('actions:login.btnLabel')}
				</button>
			</div>
		</div>
	)
}
