export const cocoonPrivateRoutes = [
	{ path: /\/booking\/.*/, mode: 'redirect' },
	{ path: /\/favorites$/, mode: 'redirect' },
	{ path: /\/events$/, mode: 'redirect' },
	{ path: /\/account$/, mode: 'redirect' },
	{ path: /\/account\/.*/, mode: 'redirect' },
	{ path: /\/dashboard$/, mode: 'redirect' },
	{ path: /\/payment\/.*/, mode: 'dialog' },
	{ path: /\/overstays\/.*/, mode: 'redirect' },
]
