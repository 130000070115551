import { Separator } from '@cocoonspace/ui/components/separator'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { BaselineAndRating } from './baseline-and-rating'

const footerLinks = [
	{
		title: 'siteFooter.cocoons.title',
		links: [
			{
				label: 'siteFooter.offers.meetingRoomsParis',
				href: '/salle-de-reunion/paris',
			},
			{
				label: 'siteFooter.offers.officesParis',
				href: '/bureau-a-l-heure/paris',
			},
			{
				label: 'siteFooter.offers.meetingRoomsLyon',
				href: '/salle-de-reunion/lyon',
			},
			{
				label: 'siteFooter.offers.officesLyon',
				href: '/bureau-a-l-heure/lyon',
			},
		],
	},
	{
		title: 'siteFooter.findUs.title',
		links: [
			{ label: 'Facebook', href: 'https://www.facebook.com/mycocoonspace' },
			{
				label: 'Instagram',
				href: 'https://www.instagram.com/cocoonspace/?hl=fr',
			},
			{ label: 'X (Twitter)', href: 'https://x.com/cocoon_space' },
			{
				label: 'LinkedIn',
				href: 'https://fr.linkedin.com/company/cocoon-space',
			},
		],
	},
	{
		title: 'siteFooter.help.title',
		links: [
			{ label: 'siteFooter.help.who', href: '/a-propos' },
			{
				label: 'siteFooter.help.faq',
				href: 'https://support.cocoon-space.com/',
			},
			{
				label: 'siteFooter.help.contact',
				href: 'mailto:contact@cocoon-space.com',
			},
		],
	},
] as const

const LegalAndSocial = () => {
	const t = useTranslations()

	return (
		<div className='flex flex-col justify-between gap-4 md:flex-row'>
			<div className='text-center text-slate-500 text-xs'>
				{`Copyright © ${new Date().getFullYear()} Cocoon Space`}
			</div>

			<div className='flex flex-col items-center justify-center gap-4 md:flex-row'>
				<div className='flex flex-row gap-4 text-xs md:text-sm'>
					<Link
						href='/reglement-cocoon'
						target='_blank'
						className='hover:text-primary'
					>
						{t('siteFooter.rules')}
					</Link>

					<Link
						href='/politique-confidentialite'
						target='_blank'
						className='hover:text-primary'
					>
						{t('siteFooter.confid')}
					</Link>

					<Link
						href='/mentions-legales'
						target='_blank'
						className='hover:text-primary'
					>
						{t('siteFooter.legal')}
					</Link>
				</div>
			</div>
		</div>
	)
}

export const SiteFooter = () => {
	const t = useTranslations()

	return (
		<footer className='bg-white print:hidden'>
			<div className='container flex flex-col gap-6 py-8'>
				<div className='flex flex-col gap-4 md:flex-row'>
					<BaselineAndRating />

					<div className='flex flex-col gap-4 md:ml-auto md:flex-row md:gap-[100px]'>
						{footerLinks.map((section, i) => (
							<ul
								key={i}
								className='flex flex-col'
							>
								<li className='mb-1 font-bold'>{t(section.title)}</li>

								{section.links.map((link) => (
									<li key={link.label}>
										<Link
											href={link.href}
											target='_blank'
											className='text-sm hover:text-primary'
										>
											{t.has(link.label as any)
												? t(link.label as any)
												: link.label}
										</Link>
									</li>
								))}
							</ul>
						))}
					</div>
				</div>

				<Separator />
				<LegalAndSocial />
			</div>
		</footer>
	)
}
