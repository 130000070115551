import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		VariantProps<typeof inputVariants> {}

export const inputVariants = cva(
	[
		'flex w-full border px-4 text-base shadow-sm transition-colors aria-[invalid="true"]:border-destructive',
		'focus-visible:outline-none disabled:cursor-not-allowed disabled:text-muted-foreground',
		'placeholder:text-foreground/50 placeholder:text-sm',
		'file:border-0 file:bg-transparent file:font-medium file:text-sm',
	],
	{
		variants: {
			variant: {
				default: 'border bg-transparent',
				primary: 'border-primary bg-transparent',
				noBorder: 'border-transparent bg-card text-card-foreground',
			},
			inputSize: {
				default: 'h-[40px] rounded-lg text-base',
				sm: 'h-[32px] rounded text-sm',
			},
		},
		defaultVariants: {
			variant: 'default',
			inputSize: 'default',
		},
	},
)

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, variant, inputSize, type, ...props }, ref) => (
		<input
			ref={ref}
			type={type}
			className={inputVariants({ inputSize, variant, className })}
			{...props}
		/>
	),
)

Input.displayName = 'Input'

export { Input }
