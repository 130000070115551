import type { Space } from '@cocoonspace/sdk-js/types/space'
import { cn } from '@cocoonspace/ui/lib/utils'
import { ExpandIcon } from 'lucide-react'
import type { ComponentProps } from 'react'

export const SpaceSurface = ({
	space,
	className,
	...props
}: { space: Pick<Space, 'surface'> } & ComponentProps<'div'>) => (
	<div
		data-testid='space-surface'
		className={cn('flex flex-row items-center', className)}
		{...props}
	>
		<ExpandIcon
			size={14}
			className='mr-1'
		/>

		{`${space.surface} m²`}
	</div>
)
