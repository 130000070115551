// @ts-ignore
import mixpanelPlugin from '@analytics/mixpanel'
import { Analytics } from 'analytics'
import { useRouter } from 'next/router'
import { parse } from 'qs'
import { useEffect, useState } from 'react'
import { useTracking } from '~/providers/tracking-provider'

declare global {
	interface Window {
		mixpanel: any
	}
}

const IS_BROWSER = typeof window !== 'undefined'

const campaignKeywords = [
	'utm_source',
	'utm_medium',
	'utm_campaign',
	'utm_content',
	'utm_term',
]

export const getSource = () => {
	if (!document.referrer.length) {
		return 'direct'
	}
	if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
		return 'Google'
	}
	if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
		return 'Bing'
	}
	if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
		return 'Yahoo'
	}
	if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
		return 'Facebook'
	}
	if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
		return 'Twitter'
	}
	return 'Other'
}

/* Initialize index */
export const analytics = Analytics({
	app: 'cocoon-web',
	// debug: process.env.NEXT_PUBLIC_APP_ENV !== 'prod',
	plugins: IS_BROWSER
		? [
				mixpanelPlugin({
					enabled: true,
					// enabled: false,
					token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
					customScriptSrc: `${process.env.NEXT_PUBLIC_API_BASE_URL}/mp/lib.min.js`,
					pageEvent: 'Visit',
					options: {
						api_host:
							process.env.NODE_ENV === 'development'
								? 'https://api-js.mixpanel.com'
								: `${process.env.NEXT_PUBLIC_API_BASE_URL}/mp`,
						debug: process.env.NEXT_PUBLIC_APP_ENV !== 'prod',
						loaded() {
							const query = parse(window.location.search)
							const source = getSource()

							console.log({ query })

							const setUser: Record<string, string> = {
								viewportSize: `${window.innerWidth}x${window.innerHeight}`,
							}

							const setUserOnce: Record<string, string> = {
								$created: new Date().toISOString(),
								Source: source,
							}

							// biome-ignore lint/complexity/noForEach: <explanation>
							campaignKeywords.forEach((queryName) => {
								const value = (query[queryName] as string | undefined)?.replace(
									/\+/g,
									' ',
								)

								setUserOnce[`${queryName} [first touch]`] = value || 'none'

								if (value) {
									setUser[`${queryName} [last touch]`] = value
								}
							})

							window.mixpanel?.register({ platform: 'web' })
							window.mixpanel?.register_once({ Source: source })
							window.mixpanel?.people.set(setUser)
							window.mixpanel?.people.set_once(setUserOnce)
						},
					},
				}),
			]
		: [],
})

export const MixPanelTracking = () => {
	const router = useRouter()
	const { analytics } = useTracking()
	const [previousRoute, setPreviousRoute] = useState('')

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		analytics.page({
			route: router.route,
			previousRoute: previousRoute,
			query: router.query,
		})

		setPreviousRoute(router.route)

		window.mixpanel?.register({
			route: router.route,
			previousRoute: previousRoute,
			query: router.query,
		})
	}, [router.route, analytics.page])

	return null
}

export default MixPanelTracking
