import type { ErrorType } from '@cocoonspace/sdk-js/axios-client/use-custom-instance'
import {
	type getAccount,
	useGetAccount,
} from '@cocoonspace/sdk-js/domains/account/account'
import {
	type getMe,
	useGetMe,
} from '@cocoonspace/sdk-js/domains/profile/profile'
import type { UnauthorizedResponse } from '@cocoonspace/sdk-js/types/unauthorizedResponse'
import type { UnexpectedResponse } from '@cocoonspace/sdk-js/types/unexpectedResponse'
import type { UseQueryResult } from '@tanstack/react-query'
import {
	type PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
} from 'react'
import { useTracking } from './tracking-provider'

// biome-ignore lint/complexity/noBannedTypes: <explanation>
type UserProviderProps = {}

export interface UserContextType {
	userQuery: UseQueryResult<
		Awaited<ReturnType<typeof getMe>>['data'],
		ErrorType<UnauthorizedResponse | UnexpectedResponse>
	>
	accountQuery: UseQueryResult<
		Awaited<ReturnType<typeof getAccount>>['data'],
		ErrorType<UnauthorizedResponse | UnexpectedResponse>
	>
	isIncompleteProfile: boolean
	isInfosPersoIncomplete: boolean
	isInfoFactuIncomplete: boolean
	isEmptyProfile: boolean
	isBusiness: boolean
}

export const UserContext = createContext<UserContextType>({} as UserContextType)

export const useUserContext = () => useContext(UserContext)

export const UserProvider = ({
	children,
}: PropsWithChildren<UserProviderProps>) => {
	const { analytics } = useTracking()

	const userQuery = useGetMe(undefined, {
		query: { select: (res) => res.data },
	})

	const accountQuery = useGetAccount({
		query: { select: (res) => res.data },
	})

	/** Identify user on mixpanel */
	useEffect(() => {
		const mixpanelUserId = analytics.user?.().userId

		if (
			userQuery.data?.distinct_id &&
			!mixpanelUserId &&
			userQuery.data?.distinct_id !== mixpanelUserId
		) {
			analytics.identify(userQuery.data?.distinct_id)
		}
	}, [userQuery.data, analytics])

	const isInfosPersoIncomplete = useMemo(
		() =>
			!userQuery.data?.email ||
			!userQuery.data?.last_name ||
			!userQuery.data?.first_name ||
			!userQuery.data?.phone,
		[
			userQuery.data?.email,
			userQuery.data?.last_name,
			userQuery.data?.first_name,
			userQuery.data?.phone,
		],
	)

	const isInfoFactuIncomplete = useMemo(() => {
		const billingAddress = accountQuery.data?.billing_address

		return (
			!billingAddress?.city ||
			!billingAddress?.country ||
			!billingAddress?.postal_code ||
			!billingAddress?.address
		)
	}, [accountQuery.data?.billing_address])

	const isIncompleteProfile = useMemo(
		() => isInfosPersoIncomplete || isInfoFactuIncomplete,
		[isInfosPersoIncomplete, isInfoFactuIncomplete],
	)

	const isEmptyProfile = useMemo(() => {
		const user = userQuery.data
		const billingAddress = accountQuery.data?.billing_address

		return (
			!user?.email &&
			!user?.last_name &&
			!user?.first_name &&
			!user?.phone &&
			!billingAddress?.city &&
			!billingAddress?.country &&
			!billingAddress?.postal_code &&
			!billingAddress?.address
		)
	}, [accountQuery.data?.billing_address, userQuery.data])

	const isBusiness = useMemo(
		() => !!accountQuery.data?.business,
		[accountQuery.data?.business],
	)

	return (
		<UserContext.Provider
			value={{
				userQuery,
				accountQuery,
				isEmptyProfile,
				isInfosPersoIncomplete,
				isInfoFactuIncomplete,
				isIncompleteProfile,
				isBusiness,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
