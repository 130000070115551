import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@cocoonspace/ui/components/form'
import type { FormControlledInput } from '@cocoonspace/ui/components/form-controlled-input'
import { Input } from '@cocoonspace/ui/components/input'
import { Trans } from 'next-i18next'
import type { ComponentProps } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'

interface ValidatedEmailInputProps
	extends Omit<ComponentProps<typeof FormControlledInput>, 'render'> {
	label?: string
	required?: boolean
}

const DidYouMeanButton = ({
	email,
	onClick,
}: { email: string; onClick: VoidFunction }) => (
	<Trans
		parent='p'
		i18nKey='common:didYouMean'
		className='flex items-center gap-x-1 self-start pt-1 text-xs'
		values={{ email }}
		components={{
			button: (
				<button
					type='button'
					className='font-semibold underline'
					onClick={onClick}
				/>
			),
		}}
	/>
)

export const ValidatedEmailInput = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	label,
	required,
	...props
}: ValidatedEmailInputProps) => {
	return (
		<FormField
			{...props}
			render={({ field, fieldState }) => (
				<FormItem>
					{!!label && (
						<FormLabel>
							{label}

							{!!required && <sup>*</sup>}
						</FormLabel>
					)}

					<FormControl>
						<Input
							{...field}
							type='email'
						/>
					</FormControl>

					{fieldState.error?.type === 'did_you_mean' ? (
						<DidYouMeanButton
							email={fieldState.error.message!}
							onClick={() => field.onChange?.(fieldState.error?.message as any)}
						/>
					) : (
						<FormMessage />
					)}
				</FormItem>
			)}
		/>
	)
}
