import { buttonVariants } from '@cocoonspace/ui/components/button'
import { SearchIcon } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useUserContext } from '~/providers/user-provider'

export const FindACocoonBtn = () => {
	const { t } = useTranslation()
	const { userQuery } = useUserContext()

	return (
		<Link
			data-testid='find-cocoon-button'
			className={buttonVariants({ variant: 'gradient' })}
			href={`/${userQuery.data?.default_zone || 'paris'}`}
		>
			<SearchIcon className='mr-2 size-4' />

			{t('cwNavbar:findCocoon')}
		</Link>
	)
}
