import type { DeskType } from '@cocoonspace/sdk-js/types/deskType'
import { cn } from '@cocoonspace/ui/lib/utils'
import { useTranslation } from 'next-i18next'
import type { ComponentProps } from 'react'

export const DeskTypeBadge = ({
	type,
	className,
	...props
}: { type?: DeskType | 'hotel' } & ComponentProps<'div'>) => {
	const { t } = useTranslation()

	if (!type) return null

	return (
		<div
			{...props}
			className={cn(
				'flex h-[22px] shrink-0 items-center justify-center rounded-[8px] px-3 font-bold text-[10px] text-white uppercase leading-none',
				{
					'bg-slate-800': type === 'coworking',
					'bg-amber-700': type === 'cafe',
					'bg-primary': type === 'cocoon',
					'bg-sky-600': type === 'hotel',
				},
				className,
			)}
		>
			{t(`space:deskTypes.${type}`)}
		</div>
	)
}
