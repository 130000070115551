import { SvgArrowRight } from '@cocoonspace/icons/react/font-awesome/regular/arrow-right'
import { SvgBellConcierge } from '@cocoonspace/icons/react/font-awesome/regular/bell-concierge'
import { SvgCalendarCheck } from '@cocoonspace/icons/react/font-awesome/regular/calendar-check'
import { SvgClockTwo } from '@cocoonspace/icons/react/font-awesome/regular/clock-two'
import { SvgKey } from '@cocoonspace/icons/react/font-awesome/regular/key'
import { SvgSparkles } from '@cocoonspace/icons/react/font-awesome/regular/sparkles'
import { SvgTv } from '@cocoonspace/icons/react/font-awesome/regular/tv'
import { buttonVariants } from '@cocoonspace/ui/components/button'
import { Card } from '@cocoonspace/ui/components/card'
import { TwicImg } from '@twicpics/components/react'
import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { SectionTitle } from '~/domains/home/components/section-title'
import { SectionWrapper } from '~/domains/home/components/section-wrapper'

export const HowCocoonDesk = () => {
	const { t } = useTranslation()

	return (
		<>
			<div className='relative overflow-hidden bg-white pt-32 md:pt-12'>
				<div
					className='absolute inset-x-0 top-0 z-[1] h-[200px] w-full'
					style={{
						background:
							'radial-gradient(46.94% 54.87% at 50% 0%, rgba(255, 67, 56, 0.28) 0%, rgba(255, 67, 56, 0.00) 100%)',
					}}
				/>

				<SectionWrapper>
					<SectionTitle>
						<Trans i18nKey='cwHome:how.cocoon.title' />
					</SectionTitle>

					<div className='flex flex-col gap-6 md:flex-row'>
						<Card className='relative aspect-video overflow-hidden rounded bg-cover md:w-[550px]'>
							<TwicImg
								src='/spaces/cjj2sba23akg00lc6h20'
								ratio='3/2'
								focus='50px100p'
							/>
						</Card>

						<div className='flex flex-1 flex-col gap-6 md:p-6'>
							<Trans
								parent='div'
								className='text-lg md:text-4xl'
								i18nKey='cwHome:how.cocoon.subTitle'
								components={{ strong: <strong className='text-primary' /> }}
							/>

							<div className='flex flex-col gap-6 md:grid md:grid-cols-2'>
								<div className='flex flex-col gap-2 text-sm'>
									<strong className='flex flex-row items-center gap-2'>
										<SvgClockTwo />

										{t('cwHome:how.cocoon.list.fast.title')}
									</strong>

									<div className='text-sm'>
										{t('cwHome:how.cocoon.list.fast.body')}
									</div>
								</div>

								<div className='flex flex-col gap-2 text-sm'>
									<strong className='flex flex-row items-center gap-2'>
										<SvgKey />

										{t('cwHome:how.cocoon.list.safe.title')}
									</strong>

									<div className='text-sm'>
										{t('cwHome:how.cocoon.list.safe.body')}
									</div>
								</div>

								<div className='flex flex-col gap-2 text-sm'>
									<strong className='flex flex-row items-center gap-2'>
										<SvgCalendarCheck />

										{t('cwHome:how.cocoon.list.flexible.title')}
									</strong>

									<div className='text-sm'>
										{t('cwHome:how.cocoon.list.flexible.body')}
									</div>
								</div>

								<div className='flex flex-col gap-2 text-sm'>
									<strong className='flex flex-row items-center gap-2'>
										<SvgTv />

										{t('cwHome:how.cocoon.list.equiped.title')}
									</strong>

									<div className='text-sm'>
										{t('cwHome:how.cocoon.list.equiped.body')}
									</div>
								</div>
							</div>
						</div>
					</div>

					<Link
						href='/paris'
						className={buttonVariants({
							variant: 'textPrimary',
							className: 'self-end',
						})}
					>
						{t('cwHome:how.cocoon.seeOurCocoons')}

						<SvgArrowRight className='ml-2' />
					</Link>
				</SectionWrapper>
			</div>

			<SectionWrapper>
				<SectionTitle>
					<Trans i18nKey='cwHome:how.desks.title' />
				</SectionTitle>

				<div className='flex flex-col gap-6 md:flex-row'>
					<div className='order-2 flex flex-1 flex-col gap-6 md:order-1 md:p-6'>
						<Trans
							parent='div'
							className='text-lg md:text-4xl'
							defaults='cwHome:how.desks.subTitle'
							components={{ strong: <strong className='text-primary' /> }}
						/>

						<div className='flex flex-col gap-6 md:grid md:grid-cols-2'>
							<div className='flex flex-col gap-2 text-sm'>
								<strong className='flex flex-row items-center gap-2'>
									<SvgClockTwo />

									{t('cwHome:how.desks.list.fast.title')}
								</strong>

								<div className='text-sm'>
									{t('cwHome:how.desks.list.fast.body')}
								</div>
							</div>

							<div className='flex flex-col gap-2 text-sm'>
								<strong className='flex flex-row items-center gap-2'>
									<SvgCalendarCheck />

									{t('cwHome:how.desks.list.flexible.title')}
								</strong>

								<div className='text-sm'>
									{t('cwHome:how.desks.list.flexible.body')}
								</div>
							</div>

							<div className='flex flex-col gap-2 text-sm'>
								<strong className='flex flex-row items-center gap-2'>
									<SvgBellConcierge />

									{t('cwHome:how.desks.list.services.title')}
								</strong>

								<div className='text-sm'>
									<Trans i18nKey='cwHome:how.desks.list.services.body' />
								</div>
							</div>

							<div className='flex flex-col gap-2 text-sm'>
								<strong className='flex flex-row items-center gap-2'>
									<SvgSparkles />

									{t('cwHome:how.desks.list.expCocoon.title')}
								</strong>

								<div className='text-sm'>
									<Trans i18nKey='cwHome:how.desks.list.expCocoon.body' />
								</div>
							</div>
						</div>
					</div>

					<Card className='relative order-1 aspect-video rounded bg-cover md:order-2 md:w-[550px]'>
						<Image
							src='/images/desks/coworking.jpg'
							alt='coworking'
							fill
							className='z-0 overflow-hidden rounded object-cover object-bottom'
						/>
					</Card>
				</div>

				<Link
					href='/desks/paris'
					className={buttonVariants({
						variant: 'textPrimary',
						className: 'self-end',
					})}
				>
					{t('cwHome:how.desks.discoverOffer')}

					<SvgArrowRight className='ml-2' />
				</Link>
			</SectionWrapper>
		</>
	)
}
