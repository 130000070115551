import { Input } from '@cocoonspace/ui/components/input'
import { cn } from '@cocoonspace/ui/lib/utils'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import {
	type ComponentPropsWithoutRef,
	type ComponentRef,
	forwardRef,
	useState,
} from 'react'

export const PasswordCreationInput = forwardRef<
	ComponentRef<typeof Input>,
	ComponentPropsWithoutRef<typeof Input>
>((props, ref) => {
	const [showMdp, setShowMdp] = useState(false)

	const passType = showMdp ? 'text' : 'password'
	const Icon = showMdp ? EyeOffIcon : EyeIcon

	return (
		<div className='relative'>
			<Input
				{...props}
				ref={ref}
				type={passType}
				className={cn('pr-12', props.className)}
			/>

			<button
				className='absolute inset-0 left-auto flex h-full w-12 items-center justify-center'
				type='button'
				onClick={() => setShowMdp((showMdp) => !showMdp)}
			>
				<Icon size={18} />
			</button>
		</div>
	)
})

PasswordCreationInput.displayName = 'PasswordCreationInput'
