import { cn } from '@cocoonspace/ui/lib/utils'
import { StarIcon } from 'lucide-react'
import { useTranslations } from 'next-intl'
import { AppsFooter } from './apps-footer'

export const Rating = ({
	defaultValue,
	className,
}: {
	defaultValue: number
	className?: string
}) => {
	const valueRoundedToLowerHalf = Math.floor(defaultValue * 2) / 2

	return (
		<div className={cn('flex gap-x-0.5', className)}>
			{Array.from({ length: 5 }, (_, i) => {
				const diff = Math.min(Math.abs(i - valueRoundedToLowerHalf), 1)

				return (
					<div
						key={i}
						className='relative'
					>
						<div
							style={{
								width: i < defaultValue ? `${100 * diff}%` : 0,
							}}
							className='absolute overflow-hidden'
						>
							<StarIcon
								className='size-4 fill-primary stroke-primary'
								strokeWidth={0}
							/>
						</div>

						<StarIcon
							className='size-4 fill-slate-200'
							strokeWidth={0}
						/>
					</div>
				)
			})}
		</div>
	)
}

export const BaselineAndRating = () => {
	const t = useTranslations()

	return (
		<div className='flex flex-col gap-6'>
			<div className='flex flex-col gap-2 leading-none'>
				<p className='font-bold text-[30px] text-primary'>cocoon space</p>

				<p>{t('siteFooter.baseline')}</p>

				<div className='flex items-center text-sm'>
					4,8
					<Rating
						className='mx-2'
						defaultValue={4.8}
					/>
					<a
						href='https://www.google.com/maps/search/cocoon+space/@48.8665006,2.306261,13z?entry=ttu'
						target='_blank'
						className='text-slate-500'
						rel='noreferrer'
					>
						449 avis
					</a>
				</div>
			</div>

			<AppsFooter />
		</div>
	)
}
