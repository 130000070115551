import { getMessages } from '@cocoonspace/i18n/lib/get-messages'
import { defaultI18nNs } from '@cocoonspace/shared/config/default-i-18-n-ns-config'
import { motion, useScroll } from 'motion/react'
import type { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect, useState } from 'react'
import { FindACocoonBtn } from '~/components/nav-bar/find-a-cocoon-btn'
import { AppNavbar } from '~/components/nav-bar/nav-bar'
import { SiteFooter } from '~/components/site-footer'
import { HomePage } from '~/domains/home/components/pages/home-page'

export default () => {
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const { scrollY } = useScroll()

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setIsSearchVisible(false)

		const unsub = scrollY.on('change', (y) => setIsSearchVisible(y > 450))

		return () => unsub()
	}, [])

	return (
		<>
			<AppNavbar>
				{isSearchVisible && (
					<motion.div
						exit={{ opacity: 0, scale: 1.1 }}
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
					>
						<FindACocoonBtn />
					</motion.div>
				)}
			</AppNavbar>

			<HomePage />
			<SiteFooter />
		</>
	)
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
	props: {
		messages: await getMessages(locale as string, ['siteFooter']),
		...(await serverSideTranslations(locale as string, [
			...defaultI18nNs,
			'cwHome',
		])),
	},
})
