import { APP_CONFIG } from '@cocoonspace/shared/config'
import { TooltipProvider } from '@cocoonspace/ui/components/tooltip'
import {
	HydrationBoundary,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NextIntlClientProvider } from 'next-intl'
import PlausibleProvider from 'next-plausible'
import { useRouter } from 'next/router'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import type { PropsWithChildren } from 'react'
import { analytics } from '~/lib/analytics'
import { TrackingProvider } from '~/providers/tracking-provider'
import { UserProvider } from '~/providers/user-provider'

const queryClient = new QueryClient()

export const Providers = ({
	children,
	pageProps,
}: PropsWithChildren<{ pageProps: any }>) => {
	const router = useRouter()

	return (
		<NextIntlClientProvider
			locale={router.locale}
			timeZone='Europe/Paris'
			messages={pageProps.messages}
		>
			<PlausibleProvider
				enabled
				domain={
					APP_CONFIG.appEnv === 'preprod'
						? 'preprod.cocoon-space.dev'
						: 'cocoon-space.com'
				}
			>
				<NuqsAdapter>
					<QueryClientProvider client={queryClient}>
						<HydrationBoundary state={pageProps.dehydratedState}>
							<TrackingProvider instance={analytics}>
								<UserProvider>
									<TooltipProvider delayDuration={300}>
										{children}
									</TooltipProvider>
								</UserProvider>
							</TrackingProvider>
						</HydrationBoundary>

						<ReactQueryDevtools buttonPosition='top-left' />
					</QueryClientProvider>
				</NuqsAdapter>
			</PlausibleProvider>
		</NextIntlClientProvider>
	)
}
