import { cn } from '@cocoonspace/ui/lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import type { ComponentProps } from 'react'

export const AppsFooter = (props: ComponentProps<'ul'>) => (
	<ul
		{...props}
		className={cn('flex gap-2', props.className)}
	>
		<li>
			<Link
				href='https://apps.apple.com/fr/app/cocoon-space/id1217025853'
				target='_blank'
			>
				<Image
					src='/images/v2/badges/apple_badge.png'
					alt='IOS'
					width={150 / 1.2}
					height={45 / 1.2}
				/>
			</Link>
		</li>

		<li>
			<Link
				href='https://play.google.com/store/apps/details?id=com.cocoonspaceapp'
				target='_blank'
			>
				<Image
					src='/images/v2/badges/google_badge.png'
					alt='Android'
					width={150 / 1.2}
					height={45 / 1.2}
				/>
			</Link>
		</li>
	</ul>
)
