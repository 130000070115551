import { useListSpaces } from '@cocoonspace/sdk-js/domains/spaces/spaces'
import type { ListSpacesParams } from '@cocoonspace/sdk-js/types/listSpacesParams'
import type { Space } from '@cocoonspace/sdk-js/types/space'
import {
	Carousel,
	CarouselContent,
	CarouselIndicators,
	CarouselItem,
} from '@cocoonspace/ui/components/carousel'
import { cn } from '@cocoonspace/ui/lib/utils'
import { SpaceCard } from '~/domains/search/components/space-card'
import { twTheme } from '~/theme'

interface DynamicCarouselProps {
	filters: ListSpacesParams
}

interface StaticCarouselProps {
	spaces: Space[]
}

export type SpaceCarouselProps = {
	slidesToScrollXs?: number
	slidesToScrollSm?: 1 | 2 | 3
	slidesToScrollMd?: 1 | 2 | 3 | 4
	className?: string
	onCardClick?: (space: Space) => void
} & (DynamicCarouselProps | StaticCarouselProps)

const ItemsCarousel = ({
	spaces,
	slidesToScrollXs = 1,
	slidesToScrollSm = 2,
	slidesToScrollMd = 3,
	className,
	onCardClick,
}: Omit<SpaceCarouselProps, 'filters'> & StaticCarouselProps) => (
	<Carousel
		className={cn('flex flex-col gap-4', className)}
		opts={{
			breakpoints: {
				[`(max-width: ${twTheme.theme.screens.sm})`]: {
					slidesToScroll: slidesToScrollXs,
					align: 'center',
					skipSnaps: false,
				},
				[`(min-width: ${twTheme.theme.screens.sm})`]: {
					slidesToScroll: slidesToScrollSm,
					align: 'start',
				},
				[`(min-width: ${twTheme.theme.screens.md})`]: {
					slidesToScroll: slidesToScrollMd,
				},
			},
		}}
	>
		<CarouselContent>
			{spaces.map((space) => (
				<CarouselItem
					key={space.id}
					className='sm:basis-1/2 md:basis-1/3'
				>
					<div className='p-[2px]'>
						<SpaceCard
							draggable={false}
							space={space}
							href={`/${space.zone}/${space.slug}`}
							onClick={() => onCardClick?.(space)}
						/>
					</div>
				</CarouselItem>
			))}
		</CarouselContent>

		<CarouselIndicators />
	</Carousel>
)

const StaticCarousel = (props: SpaceCarouselProps & StaticCarouselProps) => (
	<ItemsCarousel {...props} />
)

const DynamicCarousel = ({
	filters,
	...props
}: SpaceCarouselProps & DynamicCarouselProps) => {
	const { data: spaces } = useListSpaces(filters)

	return (
		<ItemsCarousel
			{...props}
			spaces={spaces?.data || []}
		/>
	)
}

export const SpaceCarousel = (props: SpaceCarouselProps) => {
	if ('spaces' in props) return <StaticCarousel {...props} />

	return <DynamicCarousel {...props} />
}
