import { cn } from '@cocoonspace/ui/lib/utils'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

const buttonVariants = cva(
	[
		'inline-flex items-center justify-center whitespace-nowrap transition-colors',
		'disabled:pointer-events-none disabled:opacity-50',
		'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
	],
	{
		variants: {
			variant: {
				default: [
					'rounded-full bg-primary font-bold text-primary-foreground',
					'hover:bg-[#eb3e34]',
				],
				gradient: [
					'rounded-full bg-gradient-to-r from-primary to-[#ff5c28] font-bold text-primary-foreground',
					'hover:from-primary-[#ff5c28] hover:to-primary-[#ff5c28] disabled:bg-primary disabled:text-white',
				],
				outline: [
					'rounded-full border border-foreground font-bold text-foreground hover:border-primary',
					'hover:text-primary',
				],
				outlinePrimary: [
					'rounded-full border border-primary font-bold text-primary',
					'hover:bg-primary hover:text-primary-foreground',
				],
				ghost: [
					'rounded-full border border-transparent font-bold text-foreground',
					'hover:border-slate-200 hover:bg-slate-200',
				],
				link: 'text-foreground underline underline-offset-[6px] hover:decoration-transparent',
				text: 'text-foreground hover:text-primary',
				textPrimary: 'font-bold text-primary',
			},
			size: {
				default: 'text-base',
				sm: 'text-sm',
			},
			icon: {
				true: '',
			},
		},
		compoundVariants: [
			{
				variant: ['default', 'gradient', 'outline', 'outlinePrimary', 'ghost'],
				size: 'default',
				icon: false,
				class: 'h-[40px] px-[40px]',
			},
			{
				variant: ['default', 'gradient', 'outline', 'outlinePrimary', 'ghost'],
				size: 'sm',
				icon: false,
				class: 'h-[32px] px-[16px]',
			},
			{
				size: 'default',
				icon: true,
				class: 'size-[40px]',
			},
			{
				size: 'sm',
				icon: true,
				class: 'size-[32px]',
			},
		],
		defaultVariants: {
			variant: 'default',
			size: 'default',
			icon: false,
		},
	},
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, icon, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'

		return (
			<Comp
				ref={ref}
				className={cn(buttonVariants({ variant, size, icon, className }))}
				{...props}
				type={props.type || 'button'}
			/>
		)
	},
)

Button.displayName = 'Button'

export { Button, buttonVariants }
