// biome-ignore lint/style/useNodejsImportProtocol: <explanation>
const path = require('path')

const sharedNamespaces = [
	'auth',
	'common',
	'errors',
	'extras',
	'features',
	'promotions',
]

module.exports = {
	i18n: {
		debug:
			process.env.NODE_ENV === 'development' && typeof window !== 'undefined',
		reloadOnPrerender: process.env.NODE_ENV === 'development',
		defaultLocale: 'fr',
		locales: ['fr'],
		localeExtension: 'json',
		localeDetection: false,
		defaultNS: 'common',
		serializeConfig: false,
		ns: [
			...sharedNamespaces,
			'actions',
			'attributesLabels',
			'billingAddressForm',
			'booking',
			'cwCommon',
			'cwDashboard',
			'cwDistricts',
			'cwEvents',
			'cwFooter',
			'cwHome',
			'cwLandings',
			'cwNavbar',
			'eventDetails',
			'eventGuestsManager',
			'features',
			'infos',
			'infosPersoForm',
			'myAccountCoupons',
			'myAccountHub',
			'myAccountInvoices',
			'myAccountWallet',
			'myAccountMySettings',
			'myAccountPaymentMethods',
			'myAccountPersonalInfos',
			'myAccountSecurity',
			'myAccountUsersManagement',
			'myEvents',
			'notifications',
			'onboarding',
			'overstays',
			'search',
			'space',
			'values',
			'venue',
		],
		localePath: path.resolve('../../packages/locales/'),
		// localePath: (locale, namespace, missing) => {
		// 	const isSharedNamespace = sharedNamespaces.includes(namespace)

		// 	if (missing) return null

		// 	const pathToLocales = isSharedNamespace
		// 		? `../../packages/shared/locales/${locale}/${namespace}.json`
		// 		: `../../packages/locales/${locale}/${namespace}.json`

		// 	return path.resolve(pathToLocales)
		// },
	},
	use: [
		{
			init: (i18next) => {
				i18next.services.formatter.add(
					'capitalize',
					(value) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
				)

				i18next.services.formatter.add('stripEmptyCents', (value) =>
					value.replace(/\.00$/, ''),
				)

				i18next.services.formatter.add('stripEmptyMinutes', (value) =>
					value.replace('h00', 'h'),
				)
			},
			type: '3rdParty',
		},
	],
}
