import type { Space } from '@cocoonspace/sdk-js/types/space'
import { cn } from '@cocoonspace/ui/lib/utils'
import { UsersIcon } from 'lucide-react'
import type { ComponentProps } from 'react'

export const SpaceCapacity = ({
	space,
	className,
	...props
}: {
	space: Pick<Space, 'capacity'>
} & ComponentProps<'div'>) => (
	<div
		data-testid='space-capacity'
		className={cn('flex flex-row items-center', className)}
		{...props}
	>
		<UsersIcon
			size={14}
			className='mr-1'
		/>

		{space.capacity}
	</div>
)
