import type { Space } from '@cocoonspace/sdk-js/types/space'
import { capitalize } from '@cocoonspace/shared/utils/capitalize'
import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { Card, cardVariants } from '@cocoonspace/ui/components/card'
import { Skeleton } from '@cocoonspace/ui/components/skeleton'
import { Trans, useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { memo, useMemo } from 'react'
import { Price } from '~/components/price'
import { SpaceCardNearestStation } from '~/domains/search/components/space-card/space-card-nearest-station'
import { SpaceCardPrice } from '~/domains/search/components/space-card/space-card-price'
import { SpaceCapacity } from '~/domains/spaces/components/atoms/space-capacity'
import { SpaceDeskCapacity } from '~/domains/spaces/components/atoms/space-desk-capacity'
import { SpaceFavoriteButton } from '~/domains/spaces/components/atoms/space-favorite-button'
import { SpaceSurface } from '~/domains/spaces/components/atoms/space-surface'
import { DeskTypeBadge } from '~/domains/spaces/components/desk-type-badge'

const SpaceCardCarousel = dynamic(
	() =>
		import('~/domains/spaces/components/organisms/space-card-carousel').then(
			(comp) => comp.SpaceCardCarousel,
		),
	{
		ssr: false,
		loading: () => (
			<div className='aspect-[366/175] w-full animate-pulse bg-slate-200' />
		),
	},
)

export const SpaceCornerPill = ({
	space,
}: {
	space: Pick<
		Space,
		'rebate_percent' | 'new' | 'prices_starting_at' | 'opening_date'
	>
}) => {
	const { t } = useTranslation()

	const content = useMemo(() => {
		const openingDate = dateTimeUtils(space.opening_date, 'YYYY-MM-DD')

		if (openingDate.isAfter(dateTimeUtils())) {
			const date = openingDate.customFormat('dateMonthDay')
			return t('space:opening_on', { date })
		}
		if (space.new) {
			return t('common:new')
		}
		if (!space.prices_starting_at && !!space.rebate_percent) {
			return `-${space.rebate_percent}%`
		}

		return ''
	}, [space, t])

	if (!content) return null

	return (
		<div
			className='absolute top-4 left-4 z-10 inline-block rounded-full bg-white px-2 py-1 text-primary text-sm'
			data-testid='space-corner-pill'
		>
			{content}
		</div>
	)
}

const SpaceDistance = ({ distance }: { distance?: number }) =>
	distance ? (
		<Trans
			parent='span'
			data-testid='space-distance'
			className='mb-px ml-1 whitespace-nowrap text-slate-500 text-xs'
			i18nKey='space:distance'
			values={{ distance }}
		/>
	) : null

export const SpaceCard = ({
	space,
	spaceType = 'cocoon',
	showDistance,
	href,
	setLockParentScroll,
	draggable = true,
	className,
	hidePrices = false,
	hideFavBtn = false,
	...props
}: {
	space: Space
	spaceType?: 'cocoon' | 'desk'
	showDistance?: boolean
	href: Parameters<typeof Link>[0]['href']
	setLockParentScroll?: (state: boolean) => void
	draggable?: boolean
	className?: string
	onMouseOver?: () => void
	onMouseLeave?: () => void
	onClick?: () => void
	hidePrices?: boolean
	hideFavBtn?: boolean
}) => {
	const { t } = useTranslation()

	const isDesk = spaceType === 'desk'

	return (
		<Link
			{...props}
			href={href}
			target='_blank'
			title={space.title}
			className={cardVariants({
				className:
					'relative z-0 flex cursor-pointer flex-col overflow-hidden rounded-md transition-all duration-500 hover:shadow-[0_0_0_3px_#ff4338]',
			})}
		>
			<SpaceCornerPill space={space} />

			{!hideFavBtn && (
				<SpaceFavoriteButton
					className='absolute top-4 right-4 z-10 size-8 bg-white text-primary focus:outline-none'
					space={space}
				/>
			)}

			<SpaceCardCarousel
				draggable={draggable}
				space={space}
			/>

			<div className='relative grid grid-cols-4 px-4 py-2'>
				{isDesk && (
					<DeskTypeBadge
						type={space.desk_type}
						className='-mt-10 absolute top-0 left-0 rounded-l-none'
					/>
				)}

				<div className='col-span-3 flex flex-col gap-0.5'>
					<div className='flex flex-row items-end font-bold leading-none'>
						<div className='truncate'>{space.title}</div>

						{showDistance && <SpaceDistance distance={space.distance} />}
					</div>

					<SpaceCardNearestStation space={space} />

					{spaceType === 'cocoon' && (
						<div className='flex flex-row gap-3 text-xs'>
							<SpaceCapacity
								space={space}
								className='text-xs'
							/>

							<SpaceSurface
								space={space}
								className='text-xs'
							/>
						</div>
					)}

					{isDesk && (
						<div className='flex flex-row gap-3 text-xs'>
							<SpaceDeskCapacity
								space={space}
								className='text-xs'
							/>
						</div>
					)}
				</div>

				{!hidePrices && (
					<div className='col-span-1 flex flex-col items-end justify-center'>
						{!isDesk && <SpaceCardPrice space={space} />}

						{isDesk && (
							<div className='flex flex-col items-end gap-1'>
								<span className='whitespace-nowrap text-xs leading-none'>
									{capitalize(t('common:from'))}
								</span>

								<Price
									suffix='dailyLong'
									price={space.desk_price}
									className='leading-none'
									suffixClassName='mb-px text-xs'
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</Link>
	)
}

SpaceCard.skeleton = ({
	className,
}: {
	className?: string
}) => (
	<Card className={className}>
		<Skeleton className='aspect-[366/175] w-full rounded-b-none' />

		<div className='p-4'>
			<Skeleton className='mb-2 h-[16px] w-[200px]' />

			<Skeleton className='mb-2 h-[12px] w-[160px]' />

			<Skeleton className='h-[12px] w-[100px]' />
		</div>
	</Card>
)

export default memo(SpaceCard)
